<template>
  <tr>
    <td></td>
    <td>
      <v-text-field
        v-model="filterItem.name_tc__startswith"
        outlined
        dense
        clearable
        @change="onChange"
        :clear-icon-cb="onChange"
      ></v-text-field>
    </td>
    <td>
      <v-select
        v-model="filterItem.unit"
        :items="unit_items"
        outlined
        dense
        clearable
        @change="onChange"
        :clear-icon-cb="onChange"
        :item-text="(item) => $t(item.text)"
      ></v-select>
    </td>
    <td></td>
    <td></td>
  </tr>
</template>
<script>
import BaseFilter from "./BaseFilter";
import { unit_items } from "@/definition.js";
export default {
  props: {},
  data: (vm) => ({
    unit_items: unit_items,
  }),
  mixins: [BaseFilter],
  methods: {},
};
</script>