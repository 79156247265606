var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('CommonAlertDialog',{attrs:{"show":_vm.showAlert,"item":_vm.alertItem},on:{"onCloseAlert":_vm.onCloseAlert}}),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.createItem}},[_c('v-icon',[_vm._v("mdi-account-plus")]),_vm._v(" "+_vm._s(_vm.$t("create"))+" ")],1)],1),_c('CustomsTypeDialog',{attrs:{"show":_vm.showDialog,"action":_vm.action,"item":_vm.dialogItem,"unit_items":_vm.unit_items},on:{"onClose":_vm.onCloseEvent,"onSave":_vm.onSaveEvent}}),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":_vm.$t('commodity.customs-type-list')}},[_c('v-data-table',{attrs:{"headers":_vm.showHeaders,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"show-first-last-page":true,"show-current-page":true,"first-icon":"mdi-chevron-double-left","last-icon":"mdi-chevron-double-right","prev-icon":"mdi-chevron-left","next-icon":"mdi-chevron-right","items-per-page-options":[20, 50, 100]},on:{"update:options":updateOptions}})]}},{key:"header",fn:function(){return [_c('CustomsTypeFilter',{on:{"onFilterChange":_vm.onFilterChange}})]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_customs_type_name(item))+" ")]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("Translate")(_vm._f("getText")(item.unit,_vm.unit_items)))+" ")]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.order,"save-text":_vm.$t('save'),"cancel-text":_vm.$t('cancel'),"large":""},on:{"update:returnValue":function($event){return _vm.$set(item, "order", $event)},"update:return-value":function($event){return _vm.$set(item, "order", $event)},"save":function($event){return _vm.updateField(item.id,'order',item.order)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{model:{value:(item.order),callback:function ($$v) {_vm.$set(item, "order", $$v)},expression:"item.order"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.order)+" "),_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center d-flex align-center justify-space-around"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',_vm.attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("customs-type.edit"))+" ")])]}}],null,true)}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.disableItem(item,_vm.$t('commodity.customs-type'),item.name_tc)}}},'v-btn',_vm.attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("customs-type.disable"))+" ")])]}}],null,true)})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }