<template>
  <v-container
    fluid
    tag="section"
  >
    <CommonAlertDialog
      :show="showAlert"
      :item="alertItem"
      v-on:onCloseAlert="onCloseAlert"
    >
    </CommonAlertDialog>
    <v-col class="text-right">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="createItem"
      >
        <v-icon>mdi-account-plus</v-icon>
        {{ $t("create") }}
      </v-btn>
    </v-col>
    <CustomsTypeDialog
      :show="showDialog"
      :action="action"
      :item="dialogItem"
      v-on:onClose="onCloseEvent"
      v-on:onSave="onSaveEvent"
      :unit_items="unit_items"
    ></CustomsTypeDialog>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('commodity.customs-type-list')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="showHeaders"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100]"
          />
        </template>
        <template v-slot:header>
          <CustomsTypeFilter 
          v-on:onFilterChange="onFilterChange"
          ></CustomsTypeFilter>
        </template>
        <template v-slot:item.name="{ item }">
          {{ get_customs_type_name(item) }}
        </template>
        <template v-slot:item.unit="{ item }">
          {{ item.unit | getText(unit_items) | Translate }}
        </template>
        <template v-slot:item.order="{ item }">
          <v-edit-dialog
            :return-value.sync="item.order"
            @save="updateField(item.id,'order',item.order)"
            :save-text="$t('save')"
            :cancel-text="$t('cancel')"
            large
          >
            {{ item.order }} <v-icon>mdi-pencil</v-icon>
            <template v-slot:input>
              <v-text-field v-model="item.order"></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="text-center d-flex align-center justify-space-around">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <!-- <v-icon small class="mr-2" v-on="on" @click="editItem(item)"
                  >mdi-pencil</v-icon
                > -->
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="editItem(item)"
                >
                  {{ $t("customs-type.edit") }}
                </v-btn>
              </template>
              <!-- <span>{{ $t("edit") }}</span> -->
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <!-- <v-icon small class="mr-2" v-on="on" @click="disableItem(item,$t('commodity.customs-type'),item.name_tc)"
                  >mdi-block-helper</v-icon
                > -->
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="disableItem(item,$t('commodity.customs-type'),item.name_tc)"
                >
                  {{ $t("customs-type.disable") }}
                </v-btn>
              </template>
              <!-- <span>{{ $t("disable") }}</span> -->
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import CommonAlertDialog from "../dialogs/CommonAlertDialog";
import BaseTable from "./BaseTable.vue";
import CustomsTypeDialog from "../dialogs/CustomsTypeDialog"
import CustomsTypeFilter from "../filters/CustomsTypeFilter"
import { unit_items } from "@/definition.js"
export default {
  data: (vm) => ({
    url: "/commodity/customs-type/",
    headersMap: {
      id: {
        sortable: true,
        text: vm.$i18n.t("ID"),
        value: "id",
        // width: "10%",
      },
      name_tc: {
        sortable: true,
        text: vm.$i18n.t("name"),
        value: "name_tc",
        // width: "10%",
      },
      name_sc: {
        sortable: false,
        text: vm.$i18n.t("name"),
        value: "name_sc",
        // width: "10%",
      },
      unit: {
        sortable: false,
        text: vm.$i18n.t("commodity.unit"),
        value: "unit",
        // width: "10%",
      },
      unit_price: {
        sortable: true,
        text: vm.$i18n.t("commodity.unit-price"),
        value: "unit_price",
        // width: "10%",
      },
      order: {
        sortable: true,
        text: vm.$i18n.t("commodity.order"),
        value: "order",
        // width: "10%",
      },
      actions: {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
        // width: "5%"
      }
    },
    tcHeaders: ['id', 'name_tc','unit_price','order', 'actions'],
    scHeaders: ['id', 'name_sc','unit_price','order', 'actions'],
    unit_items: unit_items,
    filterItem: {
      enable: true,
    },
    defaultItem: {order:10000},
  }),
  mixins: [BaseTable],
  computed: {},
  components: {
    CustomsTypeDialog,
    CustomsTypeFilter,
    CommonAlertDialog
  },
  methods: {
    get_customs_type_name(item){
      return this.getHanText(item,"name")
    },
  },
  mounted() {
    this.getParams['enable'] = true
    this.options = {
      sortBy: ["order","id"],
      sortDesc: [true,true],
    };
  },
};
</script>
